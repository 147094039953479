.jssorb03 {
    position: absolute;
}

.jssorb03 div, .jssorb03 div:hover, .jssorb03 .av {
    position: absolute;
    width: 21px;
    height: 21px;
    text-align: center;
    line-height: 21px;
    color: white;
    font-size: 12px;
    overflow: hidden;
    cursor: pointer;
}

.jssorb03 div { 
    background-position: -5px -4px; 
}

.jssorb03 div:hover, .jssorb03 .av:hover { 
    background-position: -35px -4px; 
}

.jssorb03 .av { 
    background-position: -65px -4px; 
}

.jssorb03 .dn, .jssorb03 .dn:hover { 
    background-position: -95px -4px; 
}
       
.jssort16 .p {    
    position: absolute;    
    top: 0;    
    left: 0;    
    width: 200px; //200px
    height: 100px; //100px
}

.jssort16 .t {    
    position: absolute;    
    top: 0;    
    left: 0;    
    width: 200px; //200px
    height: 100px; //100px
    border: none;
}

.jssort16 .p img {    
    position: absolute;    
    top: 0;    
    left: 0;    
    width: 99%; // 99%
    height: 100px; //100px
    filter: alpha(opacity=55);    
    opacity: .55;    
    transition: opacity .6s;    
    -moz-transition: opacity .6s;    
    -webkit-transition: opacity .6s;    
    -o-transition: opacity .6s;
}

.jssort16 .pav img, .jssort16 .pav:hover img, .jssort16 .p:hover img {    
    filter: alpha(opacity=100);    
    opacity: 1;    
    transition: none;    
    -moz-transition: none;    
    -webkit-transition: none;    
    -o-transition: none;
}

.jssort16 .pav:hover img, .jssort16 .p:hover img {    
    filter: alpha(opacity=70);    
    opacity: .7;
}

.jssort16 .title, .jssort16 .title_back {    
    position: absolute;    
    bottom: 0px;    
    left: 0px;    
    width: 200px;    //200px
    height: 30px;    //30px
    line-height: 30px;    
    text-align: center;    
    color: #000;    
     font-size: 20px;
}

.jssort16 .title_back {    
    background-color: #fff;    
    filter: alpha(opacity=50);    
    opacity: .5;
}

.jssort16 .pav .title_back {    
    background-color: #000;    
    filter: alpha(opacity=50);    
    opacity: .5;
}

 .jssort16 .pav .title {    
    color: #fff;
}

.jssort16 .p.pav:hover .title_back, .jssort16 .p:hover .title_back {    
     filter: alpha(opacity=40);    
    opacity: .4;
}

.jssort16 .p.pdn img {    
    filter: alpha(opacity=100);    
    opacity: 1;
}

.img-slider1 {
    position:relative;
    margin:0 auto;
    top:0px;
    left:0px;
    width:100% !important; //100%
    height:400px !important; //400px
    overflow:hidden;
    visibility:hidden;
}

.img-slider2 {
    position:absolute;
    top:0px;
    left:0px;
    background-color:rgba(0,0,0,0.7);
}

.img-slider3 {
    filter: alpha(opacity=70); 
    opacity: 0.7; 
    position: absolute; 
    display: block; 
    top: 0px; 
    left: 0px; 
    width: 100%; //100%
    height: 100%; //100%
}

.img-slider4 {
    position:absolute;
    display:block;
    top:0px;
    left:0px;
    width:100%; //100%
    height:100%; //100%
}

.img-slider5 {
    cursor:default;
    position:relative;
    top:0px;
    left:0px;
    width:100%; //100%
    height:300px; //300px
    overflow:hidden;
}

.img-slider6 {
    position:absolute;
    left:0px;
    bottom:0px;
    width:445px !important; //600px
    height:100px; //100px
}

.img-slider7 {
    cursor: default;
}

.img-slider8 {
    bottom:116px;right:16px;
}

.img-slider9 {
    width:21px;height:21px;
}
