/* icon fonts */

// @mixin font-awesome {
//     font-family: 'FontAwesome';
//     font-style: normal;
//     font-weight: normal;
// }

/* descriptive stacks */

// @mixin font-ubuntu-bold {
//     font-family: 'Ubuntu', sans-serif;
//     font-style: normal;
//     font-weight: 700;
// }

// @mixin font-arial-regular {
//     font-family: Arial, sans-serif;
//     font-style: normal;
//     font-weight: 400;
// }

/* functional stacks */

// @mixin font-title-1 {
//     line-height: 1.25;
//     @include font-ubuntu-bold;
// }

// @mixin font-body-1 {
//     line-height: 1.8;
//     @include font-arial-regular;
// }

