// fancy colors
$c1: #E74C3C;
$c2: #2ECC71;
$c3: #3498DB;
$c4: #F1C40F;
$c5: #E67E22;
$c6: #9B59B6;
$c7: #95A5A6;
$c8: #34495E;
$c9: #1ABC9C;
$c0: #ECF0F1;

// fancy background colors
.bgc1 { background-color: $c1; }
.bgc2 { background-color: $c2; }
.bgc3 { background-color: $c3; }
.bgc4 { background-color: $c4; }
.bgc5 { background-color: $c5; }
.bgc6 { background-color: $c6; }
.bgc7 { background-color: $c7; }
.bgc8 { background-color: $c8; }
.bgc9 { background-color: $c9; }
.bgc0 { background-color: $c0; }

// fancy background color mixins
@mixin bgc1 { background-color: $c1; }
@mixin bgc2 { background-color: $c2; }
@mixin bgc3 { background-color: $c3; }
@mixin bgc4 { background-color: $c4; }
@mixin bgc5 { background-color: $c5; }
@mixin bgc6 { background-color: $c6; }
@mixin bgc7 { background-color: $c7; }
@mixin bgc8 { background-color: $c8; }
@mixin bgc9 { background-color: $c9; }
@mixin bgc0 { background-color: $c0; }

// fancy background gradients
@mixin bgc1 { @include gradient-directional( lighten( $c1, 10% ), darken( $c1, 10% ) ); }
@mixin bgc2 { @include gradient-directional( lighten( $c2, 10% ), darken( $c2, 10% ) ); }
@mixin bgc3 { @include gradient-directional( lighten( $c3, 10% ), darken( $c3, 10% ) ); }
@mixin bgc4 { @include gradient-directional( lighten( $c4, 10% ), darken( $c4, 10% ) ); }
@mixin bgc5 { @include gradient-directional( lighten( $c5, 10% ), darken( $c5, 10% ) ); }
@mixin bgc6 { @include gradient-directional( lighten( $c6, 10% ), darken( $c6, 10% ) ); }
@mixin bgc7 { @include gradient-directional( lighten( $c7, 10% ), darken( $c7, 10% ) ); }
@mixin bgc8 { @include gradient-directional( lighten( $c8, 10% ), darken( $c8, 10% ) ); }
@mixin bgc9 { @include gradient-directional( lighten( $c9, 10% ), darken( $c9, 10% ) ); }
@mixin bgc0 { @include gradient-directional( lighten( $c0, 10% ), darken( $c0, 10% ) ); }

// background darker lighter
@mixin bgc-darker { background-color: rgba( 0, 0, 0, 0.1 ); }
@mixin bgc-lighter { background-color: rgba( 255, 255, 255, 0.1 ); }

// add the colorloop mixin to an element
// to easily spot all elements inside it
@mixin colorloop {
	// make each one a little darker
	// @include bgc-darker
	// rainbow effect!
	&:nth-child(10n+1) { @include bgc1; }
	&:nth-child(10n+2) { @include bgc2; }
	&:nth-child(10n+3) { @include bgc3; }
	&:nth-child(10n+4) { @include bgc4; }
	&:nth-child(10n+5) { @include bgc5; }
	&:nth-child(10n+6) { @include bgc6; }
	&:nth-child(10n+7) { @include bgc7; }
	&:nth-child(10n+8) { @include bgc8; }
	&:nth-child(10n+9) { @include bgc9; }
	&:nth-child(10n+0) { @include bgc0; }
}

// @include media-breakpoint-only( xs ) { body, [canvas=container] { background-color: $c3; } } // #3498DB
// @include media-breakpoint-only( sm ) { body, [canvas=container] { background-color: $c5; } } // #E67E22
// @include media-breakpoint-only( md ) { body, [canvas=container] { background-color: $c6; } } // #9B59B6
// @include media-breakpoint-only( lg ) { body, [canvas=container] { background-color: $c2; } } // #2ECC71
// @include media-breakpoint-only( xl ) { body, [canvas=container] { background-color: $c4; } } // #F1C40F


// @include media-breakpoint-only( xs ) { body { border-top: 10px solid $c3; } } // #3498DB
// @include media-breakpoint-only( sm ) { body { border-top: 10px solid $c5; } } // #E67E22
// @include media-breakpoint-only( md ) { body { border-top: 10px solid $c6; } } // #9B59B6
// @include media-breakpoint-only( lg ) { body { border-top: 10px solid $c2; } } // #2ECC71
// @include media-breakpoint-only( xl ) { body { border-top: 10px solid $c4; } } // #F1C40F

