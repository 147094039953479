header {
	@include bg-contain;
		a {
			@include inflate;
		}

	.logo {
		@media (max-width: 768px) {
			margin: 0 auto;
            background-size: contain;
		}
	}

	.header {
        background-image: url('./images/unopt/header-back.png');
        background-repeat: no-repeat;
        background-position: center right;

        @media (max-width: 767px) {
            background-size: 35%;
            background-image: none;
        }
        @media (min-width: 768px) and (max-width: 991px) {
            background-size: 60%;
        }
        @media (min-width: 992px) and (max-width: 1199px) {
            background-size: 45%;
        }
        @media (min-width: 1200px) {
            background-size: 35%;
        }

		padding-top: 20px;
		padding-bottom: 40px;

		.logo {
            background-image: url('./images/unopt/Logo_FWTechniek.png');
            @media (max-width: 767px) {
                width: auto;
                height: 120px;
                background-size: contain;
            }
            @media (min-width: 768px) and (max-width: 991px) {
                width: 312px;
                height: 111px;
            }
            @media (min-width: 992px) and (max-width: 1199px) {
                width: 290px;
                height: 102px;
                margin: 0;
            }
            @media (min-width: 1200px) {
                width: 312px;
                height: 111px;
            }
            @include bg-cover;
			a {
				@include inflate;
			}
		}

		.slogan {
			font-size: 40px;
			line-height: 1;
			text-align: center;
			font-weight: 400;
			text-transform: uppercase;
			padding: 0;

			.slogan-sm {
				font-size: 30px;
			}

			p {
				color: $color-4;
			}

			.slogan-r1 {
				margin-bottom: 0;
                font-size: 40px;
                @media (max-width: 1199px) {
                    padding-right: 40px;
                }
                @media (min-width: 1200px) {
                    padding-right: 70px;
                }
			}

			.slogan-r2 {
				margin-bottom: 0;
				font-size: 40px;
                @media (max-width: 1199px) {
                    padding-left: 40px;
                }
                @media (min-width: 1200px) {
                    padding-left: 70px;
                }
			}
		}

		.search-box {
			padding-top: 0;
			text-align: right;

			.contact {
				float: right;
				font-size: 15px;
				margin-right: 18px;

				@media (max-width: 768px) {
					text-align: center;
                    width: 100%;
				}
				@media (min-width: 992px) and (max-width: 1199px) {
					span {
						margin-right: -10px;
					}
				}

				.col {
					padding-right: 0;
					text-align: right;
                    -webkit-appearance: none;

                    a, a:visited {
                        display: inherit;
                        text-decoration: none;
                        color: $color-4;
                    }

                    .fa {
                         color: $color-1;
                     }
				}

                .tel-mail {
                    @media (max-width: 390px) {
                        display: inline-block;
                        text-align: left;
                        margin-bottom: 10px;

                        .fa-phone {
                            padding: 2px 1px;
                        }
                    }

                    @media (min-width: 768px) and (max-width: 1199px) {
                        display: inline-block;
                        padding-right: 5px;
                    }

                    @media (min-width: 1200px) {
                        padding-left: 10px;
                    }

                    .fa-border {
                        padding: 2px 6px;
                        margin-right: 5px;
                        border: 1px solid $color-1;
                        border-radius: 50%;
                    }

                    .fa-border-env {
                        padding-bottom: 3px;
                    }
                }
			}

			.search-field {
                display: flex;
                justify-content: flex-end;
				border: none;
				margin: 10px -35px 0 0;
                padding: 0;

                .row {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                }

                form {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    margin-right: 10px;

                    @media (max-width: 576px) {
                        margin-right: 15px;
                    }
                }

//				.col-search {
//					padding-right: 0;
//				}
//
				.search-pad {
					padding-left: 0;
					@media (max-width: 576px) {
						padding-right: 35px;
					}
				}
//
				.search-input {
					border: 1px solid $color-1;
					border-right: none;
                    border-radius: 0;
                    -webkit-appearance: none;
                    text-align: right;
					padding: 5px 15px;
                    font-size: 18px;
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                    @media (min-width: 768px) {
                        width: 65%;
                    }
				}

                button {
                    border: 1px solid $color-1;
                    border-left: none;
                    padding: 8px 15px 9px 22px;
                    background-color: $color-2;
                    cursor: pointer;
                }

//				.search-symbol {
//					border: 1px solid $color-1;
//					border-left: none;
//					padding: 8px 15px 9px 22px;
//					background-color: $color-2;
//
//					@media (max-width: 576px) {
//						padding-right: 30px;
//					}
//				}

				::-webkit-input-placeholder { color: $color-1; } /* Chrome/Opera/Safari */
				::-moz-placeholder 			{ color: $color-1; } /* Firefox 19+ */
				:-ms-input-placeholder 		{ color: $color-1; } /* IE 10+ */
				:-moz-placeholder 			{ color: $color-1; } /* Firefox 18- */
			}
		}
	}

	.navbar-inverse .navbar-toggler {
				border: 2px solid rgba(255,255,255,1);
			}

	.navbar {
		background-color: $color-1 !important;
		height: 74px;

		.container {
			@media (max-width: 991px) {
				margin-top: 7px;
				z-index: 1;
			}

			@media (max-width: 575px) {
				width: 100%;
			}
		}

		#dropdown02 {
			color: $color-2;
		}

		.navbar-collapse {
			.navbar-nav {
				background-color: $color-1;
				@media (max-width: 991px) {
					margin-top: 58px;
				}
			}
		}

		.nav-item {

			color: $color-2;
			height: 74px;

			@media (max-width: 991px) {
				border-top: 1px solid $color-2;
			}
			@media (min-width: 992px) {
				border-right: 1px solid $color-2;
			}
			@media (max-width: 1199px) {
				padding: 0 9px;
			}
			@media (min-width: 1200px) {
				padding: 0 10px;
			}

			.nav-link {
				display: flex;
				align-items: center;
				font-weight: 600;
			}

			.dropdown-menu {

			}
		}

		@media (min-width: 992px) {
			.nav-item:first-child {
				border-left: 1px solid $color-2;
			}
		}

		.nav-item:hover {
			background-color: $color-6;
		}

		.nav-item:active {
			background-color: $color-6;
		}
	}

    .menu-item ul {
        background-color: $color-1;

        li:first-child {
            border: none !important;
        }

        li {
            border-right: none !important;
            border-top: 1px solid $color-2;

            a {
                display: flex;
                align-items: center;
                color: $color-2;
                font-weight: 600;
            }

            a:hover {
                background-color: $color-1;
                color: $color-2;
            }
        }
    }
}

@media (max-width: 991px) {
    .navbar-toggleable-md .navbar-nav .dropdown-menu {
        position: inherit;
    }

    header .menu-item ul {
        top: 0;
        border-radius: 0;
        border-right: 10px solid $color-6;
        border-left: 10px solid $color-6;
        border-bottom: 1px solid $color-2;
        margin: 0 -10px;
        padding: 0;

        li {
            color: $color-1;

            a:hover {
                background-color: $color-6;
            }
        }
    }
}

.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield select {
    width: 100%;
}

.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield input {
    width: 100%;
}

.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield textarea {
     width: 100%;
 }

.gform_wrapper .gform_footer:not(.top_label) {
    margin-left: 0 !important;
}

#home-banner {
    min-height:300px;
    background-image:url('./images/unopt/homebg.jpg');
    background-size:cover;
    background-repeat:no-repeat;

    .post {
        .ct {
            h2 {
                padding-top:50px;
                color:$color-6;
                font-size: 3rem;
                margin-bottom:1rem;
            }

            p {
                max-width:500px;
                font-size:19px;
                color:$color-9;
            }
          
        }
    }
}  