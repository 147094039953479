/* pre reset vendor */

// Bootstrap
// @import 'vendor/bootstrap';



/* variables */

@import 'variables/colors';
@import 'variables/sizes';

/* reset */

// @import 'partials/reset';

/* post reset vendor */

// Gravity Forms
// Font Awesome
// Owl Carousel
// Slidebars
// fancyBox
// Etc.

/* fonts */

// @import 'fonts/ubuntu';

/* mixins */

@import 'mixins/font-stacks';
@import 'mixins/developer-tools';   
@import 'mixins/mixins';

/* partials */

// @import 'partials/buttons'; 
// @import 'partials/forms';
// @import 'partials/user-content';
@import 'partials/misc';

/* component partials */

// @import 'components/off-canvas'; 
@import 'components/header';
@import 'components/image-slider';
// @import 'components/top-bar';

// @import 'components/grey-1-inner-white';
// @import 'components/grey-1-inner-grey';

// @import 'components/vision-quote';
// @import 'components/fp-cases';
// @import 'components/partner-program';
// @import 'components/trial';
// @import 'components/feats';
// @import 'components/blog-agenda';
// @import 'components/partners';
@import 'components/footer';
// @import 'components/legal-footer'; 