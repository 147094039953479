// css van alles
a {
	color: $color-1;
	text-transform: none;
	text-decoration: none;
}

a:hover {
	text-transform: none;
	color: $color-1;
	text-decoration: none;
}
a:focus, a:active {
	text-decoration:none;
}

p {
	color: #666666;
    font-size: 13px;
}

h2, h5 {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 0;
	line-height: 1.2;
}

h3 {
    font-size: 16px;
}

.home-banner {
	height: 380px;
	@include bg-cover;

	.overlay {
		background-color: rgba(0, 0, 0, 0.6);
	}

	.col {
		height: 380px;
		// padding: 83px 0;

		@media (max-width: 767px) {
			padding: 60px 0;
		}
	}

	.text-overlay {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.home-banner-text {
		color: $color-6;
		font-size: 34px;
		font-weight: 500;
		line-height: 1.2;
		text-transform: uppercase;
		text-align: center;
		padding: 44px;
        margin-bottom: 0;
		border: 2px solid $color-2;
		border-radius: 3px;
		opacity: 1;

		.home-bannum {
			color: $color-1;
			font-size: 38px;
		}

		@media (min-width: 768px) and (max-width: 991px) {
			margin: 0 -10px;
		}

		@media (min-width: 992px) and (max-width: 1200px) {
			margin: 0 10px;
        }

        @media (min-width: 1200px) {
            padding: 44px 90px;
        }
	}
}

.banner-text {
	padding: 30px 0 30px;

	color: $color-6;


   
    background-color: #e7e7e7; 
    border-bottom: 1px solid #dadada;


	h2 {
		color: $color-9;
	}

	.fw-text {
		color: $color-9;
		padding: 5px 0 22px 0;
		border-bottom: 2px solid $color-2;
		font-size: 15px;
	}

    .partner {
        color: $color-9;
        text-transform: uppercase;
        font-size: 20px;

        span {
            font-size: 26px;
        }
    }

	.banner-bottom {
		text-transform: uppercase;
		font-size: 18px;
		line-height: 1;
		color: $color-2;

		span {
			font-size: 22px;
		}
	}

	.tools-circle {
		display: flex;
		align-items: center;

        img {
            width: 164px;
            height: 164px;
        }
	}

	.tools-text {
		color: $color-1;
		font-weight: 700;
		font-size: 18px;
		line-height: 1.2;
		background-color: $color-2;
		padding: 15px 10px 15px 30px;
		margin-left: -40px;
	}
}

.highlights {
	margin: 50px 0;
	
	.high-all {
		display: flex;
		flex-wrap: wrap;
	}

	.high-left {
		@media (max-width: 767px) {
			justify-content: center;
		}
		@media (min-width: 768px) and (max-width: 991px) {
			justify-content: center;
			padding-right: 5px;
		}
		@media (min-width: 992px) {
			justify-content: flex-start;
		}
	}

	.high-mid {
		@media (max-width: 767px) {
			justify-content: center;
			padding-top: 25px;
		}
		@media (min-width: 768px) and (max-width: 991px) {
			justify-content: center;
			padding-left: 5px;
		}
		@media (min-width: 992px) {
			justify-content: center;
		}
	}
	
	.high-right {
		@media (max-width: 767px) {
			justify-content: center;
			padding-top: 25px;
		}
		@media (min-width: 768px) and (max-width: 991px) {
			justify-content: center;
			padding-top: 50px;
			padding-right: 5px;
		}
		@media (min-width: 992px) {
			justify-content: flex-end;
		}
	}

	.high-text {
		background-color: $color-1;
		color: $color-2;
		text-transform: uppercase;
		font-weight: 500;
		font-size: 14px;
		padding: 10px 12px 10px 12px;
	}

	.high-list {
		padding: 10px 0;
		border: 1px solid $color-5;
		border-top: none;
		max-width: 292px;
		font-size: 13px;
		font-weight: 500;
		color: $color-4;
		min-height: 195px;

		ul {
			margin-bottom: 0;
		}
	}

	.high-more {
		padding: 10px 18px 10px 18px;
		color: $color-1;
		border: 1px solid $color-5;
		border-top: none;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 16px;

		.more-arrow {
			float: right;
		}
	}
}

.content-banner {
    height: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .overlay {
        background-color: rgba(0, 0, 0, 0.6);
    }

    .col {
        height: 250px;

        @media (max-width: 768px) {
            padding: 60px 0;
        }
    }

    .text-overlay {
        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
            color: $color-2;
            font-size: 34px;
            font-weight: 500;
            line-height: 1.2;
            text-transform: uppercase;
            text-align: center;
            padding: 35px;
            border: 2px solid $color-2;
            border-radius: 3px;
            opacity: 1;

            span {
                color: $color-1;
                font-size: 38px;
            }

            @media (max-width: 575px) {
                overflow-wrap: break-word;
                word-break: break-word;
                -webkit-hyphens: auto;
                -ms-hyphens: auto;
                -moz-hyphens: auto;
                hyphens: auto;
            }

            @media (min-width: 768px) and (max-width: 991px) {
                margin: 0 -10px;
            }

            @media (min-width: 992px) and (max-width: 1200px) {
                margin: 0 50px;
            }
        }
    }
}

.content {
	margin: 45px 0;

    ul, ol {
        font-size: 13px;
        color: $color-3;
    }

	h2, h5 {
		margin-bottom: 15px;
	}

    button {
        margin-bottom: 5px;
        background-color: $color-1;
        border: none;
        color: $color-2;
        padding: 8px;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 14px;
        cursor: pointer;
    }

	.ct-text {
		@media (min-width: 992px) {
			padding-right: 68px;
		}
		@media (max-width: 991px) {
			padding-right: 15px;
		}
	}

	.ct-images {
		p {
			font-weight: 700;
			margin-bottom: 5px;
		}

		img {
			width: 100%;
		}

		@media (max-width: 991px) {
			padding-left: 15px;
		}

		@media (min-width: 992px) {
			padding-left: 0;
		}

		.img-all {
			width: 100%;
		}
	}

//    .ct-img-big {
//        width: 100%;
//        margin-bottom: 10px;
//    }
//
//    .owl-content-img {
//        .item {
//
//        }
//    }

    #sync1 {
        .item {
            background: #0c83e7;
            padding: 0;
            margin: 5px;
            color: #FFF;
            text-align: center;
        }
    }

    #sync2 {
        .item {
            background: #C9C9C9;
            padding: 0;
            margin: 5px;
            color: #FFF;
            text-align: center;
            cursor: pointer;
            h1 {
                font-size: 18px;
            }
        }
    }

    .owl-theme {
        .owl-nav {
            /*default owl-theme theme reset .disabled:hover links */
            [class*='owl-'] {
                transition: all .3s ease;
                &.disabled:hover {
                    background-color: #D6D6D6;
                }
            }

        }
    }

    //arrows on first carousel
    #sync1.owl-theme {
        position: relative;
        .owl-next, .owl-prev {
            width: 22px;
            height: 40px;
            margin-top: -20px;
            position: absolute;
            top: 50%;
        }
        .owl-prev {
            left: 10px;
        }
        .owl-next {
            right: 10px;
        }
    }
}

.navbar .dropdown-menu li {
  width: 100%;
  margin-left: 0 !important;
}

.navbar-inverse {
    .navbar-nav {
        .nav-link {
            color: rgba(255,255,255,1);
        }
        .nav-link:hover {
            color: rgba(255,255,255,1);
        }
    }
}

.margin-page {
    margin: 45px 0 40px 0;

    .post-page-img {
        img {
            width: 100%;
            height: auto;
            margin-bottom: 20px;
        }
    }

    .post {
        h2 {
            margin-bottom: 0;
        }
        .news-date {
            margin-bottom: 10px;
            text-transform: uppercase;
        }
    }
}

.gform_heading {
    .gform_title {
        font-size: 18px;
    }
}

.gform_body {
    ul {
        padding-left: 0;
    }
    li {
        list-style: none;
    }

    .gfield_label {
        margin: 8px 0 3px 0;
    }
}

.search-page {
    margin: 45px 0;

    .searchform {
        display: flex;
        justify-content: center;
    }

    .search-results {
        padding-left: 0;
        padding-top: 20px;
    }

    li {
        list-style: none;
        padding: 10px;
        margin-bottom: 20px;
        background-color: $color-8;

        p {
            margin-bottom: 5px;
        }
    }

    .nav-prev {
        margin-top: 10px;

        a {
            padding: 10px 20px;
            background-color: $color-1;
            color: $color-2;
        }
    }

    .nav-next {
        display: flex;
        justify-content: flex-end;

        a {
            padding: 10px 20px;
            background-color: $color-1;
            color: $color-2;
        }
    }
}

.owl-carousel {
    z-index: 0;
}

.gform_footer input {
    margin-bottom: 40px;
    background-color: #EE3F22;
    border: none;
    color: #FFFFFF;
    padding: 8px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;
    border-radius: 0;
}

.contact-map {
    margin-bottom: 10px;

    @media (max-width: 991px) {
        width: 100%;
        height: 300px;
    }
    @media (min-width: 992px) {
        width: 450px;
        height: 300px;
    }
}

.validation_message {
    color: $color-1;
}

.home-text {
    h2 {
        font-size: 2rem;
    }
}