/* flexbox */

@mixin flexbox-colhcvc {
    display: flex;
    flex-direction: column; // rotate axis
    justify-content: center; // vertical center
    align-items: center; // horizontal center
}

@mixin flexbox-colhc {
    display: flex;
    flex-direction: column; // rotate axis
    align-items: center; // horizontal center
}

@mixin flexbox-colvc {
    display: flex;
    flex-direction: column; // rotate axis
    justify-content: center; // vertical center
}

/* background */

@mixin bg-cover {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin bg-contain {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

/* size */

@mixin inflate {
    display: block;
    height: 100%;
}

.inflate {
    @include inflate;
}

/* order */

@mixin order-parent {
    display: flex;
    flex-flow: column;
}

/* embeds */

.embed-container {
    position: relative;
    display: block;
    height: 0;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 56.25%; // 16x9
    &.youtube {}
    &.vimeo {}
    &.issuu {
        padding-bottom: 41%;
        & > div > div > div:last-child {
            display: none;
        }
    }
    iframe,
    embed,
    object,
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

/* aspect ratio height */

@mixin arheight {
    position: relative;
    display: block;
    height: 0;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 100%; // 1x1
}
// .arheight {
//     @include arheight;
// }

// vertical
@mixin ar9x16 {
    padding-bottom: 177.8%;
}
@mixin ar2x3 {
    padding-bottom: 150%;
}
@mixin ar4x5 {
    padding-bottom: 120%;
}
// horizontal
@mixin ar5x4 {
    padding-bottom: 80%;
}
@mixin ar4x3 {
    padding-bottom: 75%;
}
@mixin ar3x2 {
    padding-bottom: 66.66%;
}
@mixin ar16x9 {
    padding-bottom: 56.25%;
}
@mixin ar2x1 {
    padding-bottom: 50%;
}
@mixin ar21x9 {
    padding-bottom: 42.86%;
}
@mixin ar3x1 {
    padding-bottom: 33.33%;
}
@mixin ar4x1 {
    padding-bottom: 25%;
}
@mixin ar5x1 {
    padding-bottom: 20%;
}

