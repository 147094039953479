/* descriptive */

// $color-black: #000;
// $color-white: #fff;

// $color-white-2: #F4F4F4;
// $color-white-3: #FEFEFE;
// $color-grey-1: #E4E4E4;
// $color-grey-2: #D9D9D9;
// $color-grey-3: #cccccc;
// $color-grey-6: #7D7A77;
// $color-grey-9: #2E2B27;

// $color-red-1: #C61433;
// $color-red-2: #C20020;
// $color-red-3: #C0001F;
// $color-red-4: #C10020;

/* functional */

// $color-edge-gutter: $color-white;

$color-1: #f7941e;
$color-2: #FFFFFF;
$color-3: #666666;
$color-4: #000000;
$color-5: #ECECFB;
$color-6: #333333;
$color-7: #292b2c;
$color-8: #EEEEEE;
$color-9: #776b6b;