footer {
	.question {
	display: flex;
	justify-content: center;
	background-color: $color-1;
	
		.question-text {
			border: 2px solid $color-2;
			border-radius: 3px;
			margin: 40px 15px;
			background-color: $color-1;
			color: $color-2;
			text-transform: uppercase;
			font-weight: 500;
            text-align: center;
            font-size: 20px;
            
            @media (max-width: 576px) {
                padding: 15px 20px;
            }

            @media (min-width: 577px) {
                padding: 15px 45px;
            }

            a {
                color: $color-2;
                text-transform: uppercase;
                font-weight: 500;
            }
		}
	}

	.news {
		margin: 45px 0 40px 0;
		
		.row {
			.col {
				@media (max-width: 767px) {
					margin-bottom: 20px;
				}
			}
		}

		.news-title {
            font-size: 18px;
			padding-bottom: 10px;
			margin-bottom: 40px;
			color: $color-1;
			border-bottom: 1px solid $color-5;
			text-transform: uppercase;
			font-weight: 700;
		}

        .news-image {
            overflow: hidden;

            img {
                @media (min-width: 768px) {
                    height: 235px; //tijdelijk, was max-height
                }
                @media (max-width: 767px) {
                    width: 100%;
                    height: auto;
                }
            }
        }


		.news-img {
			@media (max-width: 767px) {
				width: 100%;
			}
			@media (min-width: 768px) and (max-width: 991px) {
				max-width: 320px;
			}
			@media (min-width: 992px) and (max-width: 1199px) {
				max-width: 290px;
			}
			@media (min-width: 1200px) {
				max-width: 350px;
			}
		}

        .news-height {
            a {
                position: absolute;
                bottom: -5px;
            }

            .news-date {
                text-transform: uppercase;
                font-weight: 500;
                font-size: 14px;
                margin-bottom: 18px;
            }

            .news-text{
                color: $color-3;
                font-size: 13px;
            }

//            .news-text1 {
//                color: $color-3;
//                font-size: 13px;
//                @media (max-width: 767px) {
//                    display: none;
//                }
//                @media (min-width: 992px) and (max-width: 1199px) {
//                    display: none;
//                }
//            }
//
//            .news-text2 {
//                color: $color-3;
//                font-size: 13px;
//                @media (min-width: 768px) and (max-width: 991px) {
//                    display: none;
//                }
//                @media (min-width: 1200px) {
//                    display: none;
//                }
//            }


            .high-more {
                color: $color-1;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 16px;

                .more-arrow {
                    padding-left: 60px;
                }
            }
        }
		
		@media (min-width: 768px) and (max-width: 991px) {
			.news-headers {
				margin-top: 20px;
			}
		}

		.news-list {
			a {
				text-decoration: none;
				color: $color-4;
				display: inherit;
			}

			@media (min-width: 768px) and (max-width: 991px) {
				padding: 0 0 10px 0;

				.news-date {
					margin-bottom: 0;
				}
			}
			@media (min-width: 992px) {
				padding: 0 0 10px 20px;
			}

			.news-thumb {
				width: 70px;
				height: 70px;
			}
		}
	}

	.footer-banner {
		background-image: url('./images/unopt/footer-banner.png');
		height: 350px;
		@include bg-cover;

		@media (max-width: 576px) {
			height: 740px;
		}

		.overlay {
			background-color: rgba(0, 0, 0, 0.7);
			height: 100%;
		}

		.container {
			display: flex;
			justify-content: flex-start;

			.ftr-col {
				color: $color-2;
				width: 239px;
				padding: 0 20px 0 20px;
				margin-top: 50px;
				border-left: 1px solid $color-2;
				
				h6 {
					text-transform: uppercase;
					padding: 0 0 30px 0;
					margin: 0;
					font-size: 16px;
				}

				ul {
					list-style-type: none;
					padding: 0;
					margin: 0;
					font-size: 13px;

					a {
						color: $color-2;
					}

					a:hover {
						font-weight: 600;
					}
				}
				
				.fa-linkedin-square {
					font-size: 60px;
					color: $color-2;
                    margin-top: -4px;
					margin-bottom: 5px;
				}
			}
		
			.ftr-col1 {
				padding: 0 20px 0 0;
				border: none;
				display: block;
			}

			.ftr-col3 {
				@media (max-width: 992px) {
					display: none;
				}

				padding: 0 16px;

				img {
					height: 58px;
					width: 58px;
                    margin-right: 2px;
					margin-bottom: 5px;
				}
			}
			
			.ftr-col4 {
				@media (max-width: 768px) {
					padding-right: 0;
				}
				.contact-bottom {
					padding-top: 20px;
				}
			}

			.ftr-col5 {
				@media (max-width: 767px) {
					display: none;
				}

				padding-right: 0;

				img {
					height: 58px;
					width: 58px;
                    margin-right: 2px;
					margin-bottom: 7px;
				}
			}

			@media (max-width: 576px) {
				flex-direction: column;
				border: none;
				align-items: center;
				padding-top: 20px;

				.ftr-col {
					border-left: none;
					border-bottom: 1px solid $color-2;
					margin: 0;
					padding: 30px 0;

					h6 {
						padding-bottom: 15px;
					}
				}

				.ftr-col4 {
					border-bottom: none;
					padding-bottom: 0;
				}
			}
		}
	}

	.copyright {
		display: flex;
		justify-content: center;
		color: $color-2;
		background-color: $color-6;
		padding: 3px 0;
	}
}